import React from "react";
import { withRouter } from "react-router-dom";
import { Layout, Menu, Breadcrumb, Avatar, Dropdown, Modal, Input, message } from 'antd';
import './Frame.css'
import '../../page/basiccss.css'
import { saveToken, getUserName, getUserId } from '../../helper/localdbhelper'

import { adminRoutes } from '../../routers/index.js'
import '../../page/basiccss.css'

import { createFromIconfontCN } from '@ant-design/icons';

import { post } from "../../helper/httphelper";
import md5 from "js-md5";
const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_4607927_aaqbvmycevt.js'
});
const { SubMenu } = Menu;


const { Content, Sider } = Layout;

class Frame extends React.Component {
  state = {
    collapsed: false,
    title: adminRoutes[0].title,
    isShowEditDialog: false,
    newpassword: ''
  };
  constructor(props) {
    super(props)
    this.changePassword = this.changePassword.bind(this)
    this.exit = this.exit.bind(this)
    this.handleParentClick = this.handleParentClick.bind(this)
  }
  exit() {
    saveToken('')
    this.props.history.push('/login')
  }

  onCollapse = collapsed => {
    this.setState({ collapsed });
  };
  handleParentClick({ item, key, keyPath, domEvent }) {

    const datas = key.split('-')
    let title = datas[1]
    this.setState({
      title: title
    })
    console.log(datas[0])
    this.props.history.push(datas[0])
  }
  changePassword() {
    if (this.state.newpassword.length <= 0) {
      message.warn('请输入密码')
      return
    }
    post('/admin/changepassword', {
      id: getUserId(),
      password: md5(this.state.newpassword)
    },(error,data)=>{
      if(error){
        message.success('密码修改失败')
      }else{
        this.setState({
          isShowEditDialog: false,
          newpassword: ''
        })
        message.success('密码修改成功，请重新登录')
        saveToken('')
        this.props.history.push('/login')
      }
    })
  }

  render() {
    const { title } = this.state;

    const menu = (
      <Menu>
        <Menu.ItemGroup title='用户中心' className='menu-group'>
          <Menu.Item>你好 - {getUserName()}</Menu.Item>
          <Menu.Item><span onClick={() => this.setState({ isShowEditDialog: true })}>修改密码</span></Menu.Item>
          <Menu.Item><span onClick={this.exit}>退出登录</span></Menu.Item>
        </Menu.ItemGroup>

      </Menu>
    );
    return (
      <Layout style={{ minHeight: '100vh', borderRadius: '5px' }}>
        <Sider >
          <div className="logo v_layout h_center margin_top_xxl">
            <IconFont type={'icon-icon_diantai'} color="white" style={{ fontSize: 40 }} />
          </div>
          <Menu style={{ marginTop: 50 }} theme="dark" defaultSelectedKeys={adminRoutes[0].path + '-' + adminRoutes[0].title} mode="inline" onClick={this.handleParentClick}>
            {

              adminRoutes.map(route => {
                if (route.isshow) {
                  if (route.child && route.child.length > 0) {
                    return (<SubMenu key={route.path + "-" + route.title} icon={<IconFont type={route.icon}  style={{fontSize:20}} color="white" />} title={route.title}>
                      {
                        route.child.map(child => {
                          if (child.isshow) {
                            return (<Menu.Item icon={<IconFont type={child.icon}  style={{fontSize:16}}/>} key={child.path + "-" + child.title} onClick={this.handleClick}>
                              {child.title}
                            </Menu.Item>)
                          }
                        })
                      }
                    </SubMenu>)

                  } else {
                    return (
                      <Menu.Item key={route.path + "-" + route.title} icon={<IconFont type={route.icon}  style={{fontSize:20}}/>} onClick={this.handleClick}>
                        {route.title}
                      </Menu.Item>
                    )
                  }
                }
              })
            }
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <div className="header">
            <Dropdown className="header_avatar" overlay={menu} placement="bottomLeft" arrow>
              <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" size={48} />
            </Dropdown>

          </div>
          <Content style={{ margin: '0 16px' }}>
            {/* <Breadcrumb style={{ margin: '16px 0' }}>
              <Breadcrumb.Item>{title}</Breadcrumb.Item>
            </Breadcrumb> */}
            <div className="site-layout-background" style={{ padding: 10, minHeight: 360,backgroundColor:'#F6F8FA' }}>
              {this.props.children}
            </div>
          </Content>
        </Layout>
        <Modal visible={this.state.isShowEditDialog}
          centered
          cancelText="取消"
          okText="确定"
          width={300}
          onCancel={() => this.setState({ isShowEditDialog: false })}
          title="密码修改"
          onOk={this.changePassword}
        >
          <div className="v_layout">
            <Input value={this.state.newpassword} placeholder="请输入新密码" type="password" onChange={(e) => {
              this.setState({
                newpassword: e.target.value
              })
            }} />
          </div>
        </Modal>
      </Layout>
    );
  }
}


export default withRouter(Frame);
