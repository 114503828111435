import Loadable from "react-loadable";
import { Spin } from "antd";
const PageHelper = Loadable({
  loader: () => import("../page/setting/page_helpe"),
  loading: Spin,
});
const PageGoodOrder = Loadable({
  loader: () => import("../page/order/page_good_order"),
  loading: Spin,
});
const PageCategory = Loadable({
  loader: () => import("../page/goods/page_category"),
  loading: Spin,
});
const PageGoods = Loadable({
  loader: () => import("../page/goods/page_good"),
  loading: Spin,
});
const PageRise = Loadable({
  loader: () => import("../page/order/page_rise"),
  loading: Spin,
});
const PageCustomerMsg = Loadable({
  loader: () => import("../page/setting/page_customer_msg"),
  loading: Spin,
});
const PageFeedback = Loadable({
  loader: () => import("../page/setting/page_feedback"),
  loading: Spin,
});
const PageKnowledgeCategory = Loadable({
  loader: () => import("../page/knowledge/page_knowledge_category"),
  loading: Spin,
});

const PageKnowledge = Loadable({
  loader: () => import("../page/knowledge/page_knowledge"),
  loading: Spin,
});
const PageCoupon = Loadable({
  loader: () => import("../page/coupon/page_coupon"),
  loading: Spin,
});

const PageAccompanyingService = Loadable({
  loader: () => import("../page/accompanying/page_accompanyingservice"),
  loading: Spin,
});
const PageAccompanyingServiceItem = Loadable({
  loader: () => import("../page/accompanying/page_accompanyingserviceitem"),
  loading: Spin,
});
const PageSystemInfo = Loadable({
  loader: () => import("../page/setting/page_systeminfo"),
  loading: Spin,
});
const PageAcompany = Loadable({
  loader: () => import("../page/user/page_acompany"),
  loading: Spin,
});
const PagePatient = Loadable({
  loader: () => import("../page/user/page_patient"),
  loading: Spin,
});
const PageAd = Loadable({
  loader: () => import("../page/popularize/page_ad"),
  loading: Spin,
});

const PagePublicCity = Loadable({
  loader: () => import("../page/setting/page_publiccity"),
  loading: Spin,
});
const PageHospital = Loadable({
  loader: () => import("../page/accompanying/page_hospital"),
  loading: Spin,
});
const PageHospitalDep = Loadable({
  loader: () => import("../page/accompanying/page_hospitaldep"),
  loading: Spin,
});
const PageLogin = Loadable({
  loader: () => import("../page/page_login/page_login"),
  loading: Spin,
});
const PageHome = Loadable({
  loader: () => import("../page/page_home/page_home"),
  loading: Spin,
});

const PageCourse = Loadable({
  loader: () => import("../page/course/page_course"),
  loading: Spin,
});

const PageUserShare = Loadable({
  loader: () => import("../page/user/page_usershare"),
  loading: Spin,
});

const PageCourseCategory = Loadable({
  loader: () => import("../page/course/page_coursecategory"),
  loading: Spin,
});
const PageTestQuestion = Loadable({
  loader: () => import("../page/testquestion/page_testquestion"),
  loading: Spin,
});
export const mainRoutes = [
  {
    path: "/login",
    component: PageLogin,
  },
];
export const adminRoutes = [
  {
    title: "首页",
    icon: "icon-shouye",
    isshow: true,
    path: "/admin/home",
    component: PageHome,
  },
  {
    title: "用户管理",
    icon: "icon-yonghuguanli",
    isshow: true,
    child: [
      {
        title: "病人管理",
        icon: "icon-bingrenguanli",
        isshow: true,
        path: "/admin/patient",
        component: PagePatient,
      },
      {
        title: "陪诊师管理",
        icon: "icon-peizhenshi1",
        isshow: true,
        path: "/admin/acompany_people",
        component: PageAcompany,
      },
      {
        title: "用户发布管理",
        icon: "icon-peizhenshi1",
        isshow: true,
        path: "/admin/user_share",
        component: PageUserShare,
      },

    ],
  },
  // {
  //   title: '订单管理',
  //   icon: 'icon-dingdan',
  //   isshow: true,
  //   child:[
  //     {
  //       title: '陪诊订单',
  //       icon: 'icon-dingdan',
  //       isshow: true,
  //       path: "/admin/print_device",
  //     },{
  //       title: '问题订单',
  //       icon: 'icon-dingdan',
  //       isshow: true,
  //       path: "/admin/mohe",
  //     },{
  //       title: '商品订单',
  //       icon: 'icon-dingdan',
  //       isshow: true,
  //       path: "/admin/mohe",
  //     },{
  //       title: '支付记录',
  //       icon: 'icon-zhifujilu',
  //       isshow: true,
  //       path: "/admin/mohe",
  //     },
  //   ]
  // },
  // {
  //   title: '商品管理',
  //   icon: 'icon-shangpinguanli',
  //   isshow: true,
  // },
  {
    title: "陪诊管理",
    icon: "icon-peizhenfuwu",
    isshow: true,
    child: [
      {
        title: "陪诊服务",
        icon: "icon-peizhenfuwu",
        isshow: true,
        path: "/admin/accompany/service",
        component: PageAccompanyingService,
      },
      {
        title: "陪诊子项目",
        icon: "icon-peizhenfuwu",
        isshow: true,
        path: "/admin/accompany/service_item",
        component: PageAccompanyingServiceItem,
      },
      {
        title: "医院管理",
        icon: "icon-yiyuan",
        isshow: true,
        path: "/admin/accompany/hospital",
        component: PageHospital,
      },
      {
        title: "科室管理",
        icon: "icon-yiyuankeshi",
        isshow: true,
        path: "/admin/accompany/hospitaldep",
        component: PageHospitalDep,
      },
    ],
  },
  // {
  //   title: '资料管理',
  //   icon: 'icon-ziliaoku',
  //   isshow: true,
  //   child:[
  //     {
  //       title: '分类管理',
  //       icon: 'icon-fenlei',
  //       isshow: true,
  //       path:''
  //     },
  //     {
  //       title: '资料列表',
  //       icon: 'icon-ziliaoku',
  //       isshow: true,
  //       path:''
  //     },
  //   ]
  // },
  // {
  //   title: '课程管理',
  //   icon: 'icon-kecheng-',
  //   isshow: true,
  //   child:[
  //     {
  //       title: '分类管理',
  //       icon: 'icon-fenlei',
  //       isshow: true,
  //       path:''
  //     },
  //     {
  //       title: '课程管理',
  //       icon: 'icon-kecheng-',
  //       isshow: true,
  //       path:''
  //     },
  //     {
  //       title: '题库管理',
  //       icon: 'icon-tiku',
  //       isshow: true,
  //       path:''
  //     },
  //     {
  //       title: '试卷管理',
  //       icon: 'icon-shijuan',
  //       isshow: true,
  //       path:''
  //     },
  //   ]
  // },
  // {
  //   title: '消息管理',
  //   icon: 'icon-xiaoxiguanli',
  //   isshow: true,
  //   child:[
  //     {
  //       title: '资讯管理',
  //       icon: 'icon-zixun',
  //       isshow: true,
  //       path:''
  //     },
  //     {
  //       title: '订阅圈消息',
  //       icon: 'icon-dingyuehao',
  //       isshow: true,
  //       path:''
  //     },
  //   ]
  // },
  {
    title: "商品管理",
    icon: "icon-wodetuiguang",
    isshow: true,
    child:[
      {
        title: "分类管理",
        icon: "icon-xiaoxiguanli",
        isshow: true,
        component: PageCategory,
        path: "/admin/goods/category",
      },
      {
        title: "商品管理",
        icon: "icon-xiaoxiguanli",
        isshow: true,
        component: PageGoods,
        path: "/admin/goods/list",
      },
    ]
  },
  {
    title: "订单管理",
    icon: "icon-wodetuiguang",
    isshow: true,
    child: [
      {
        title: "评论管理",
        icon: "icon-xiaoxiguanli",
        isshow: true,
        component: PageSystemInfo,
        path: "/admin/order/systemmsg",
      },
      {
        title: "发票管理",
        icon: "icon-xiaoxiguanli",
        isshow: true,
        component: PageRise,
        path: "/admin/order/risemanager",
      },
      {
        title: "商品订单管理",
        icon: "icon-xiaoxiguanli",
        isshow: true,
        component: PageGoodOrder,
        path: "/admin/order/goodsorder",
      },
    ],
  },
  {
    title: "知识管理",
    icon: "icon-wodetuiguang",
    isshow: true,
    child: [
      {
        title: "分类管理",
        icon: "icon-xiaoxiguanli",
        isshow: true,
        component: PageKnowledgeCategory,
        path: "/admin/knowledge/category",
      },
      {
        title: "资料管理",
        icon: "icon-xiaoxiguanli",
        isshow: true,
        component: PageKnowledge,
        path: "/admin/knowledge/list",
      },
    ],
  },

  {
    title: "课程管理",
    icon: "icon-kechengfenlei",
    isshow: true,
    child: [
      {
        title: "分类管理",
        icon: "icon-kechengfenlei",
        isshow: true,
        component: PageCourseCategory,
        path: "/admin/course/category",
      },
      {
        title: "课程管理",
        icon: "icon-kechengfenlei",
        isshow: true,
        component: PageCourse,
        path: "/admin/course/manager",
      },
    ],
  },
  {
    title: "试题管理",
    icon: "icon-shiti",
    isshow: true,
    component: PageTestQuestion,
    path: "/admin/testquestion/manager",
  },
  {
    title: "推广管理",
    icon: "icon-wodetuiguang",
    isshow: true,
    child: [
      {
        title: "广告管理",
        icon: "icon-guanggao",
        isshow: true,
        component: PageAd,
        path: "/admin/popularize/ad",
      },
    ],
  },
  {
    title: "红包管理",
    icon: "icon-wodetuiguang",
    isshow: true,
    child: [
      {
        title: "红包管理",
        icon: "icon-guanggao",
        isshow: true,
        component: PageCoupon,
        path: "/admin/coupon/list",
      },
    ],
  },


  {
    title: "消息设置",
    icon: "icon-shezhi",
    isshow: true,
    child: [
      {
        title: "系统消息",
        icon: "icon-xiaoxiguanli",
        isshow: true,
        component: PageSystemInfo,
        path: "/admin/setting/systemmsg",
      },
      {
        title: "常见问题简答管理",
        icon: "icon-xiaoxiguanli",
        isshow: true,
        component: PageHelper,
        path: "/admin/setting/helper",
      },
      {
        title: "反馈管理",
        icon: "icon-xiaoxiguanli",
        isshow: true,
        component: PageFeedback,
        path: "/admin/setting/feedback",
      },
      {
        title: "APP客服消息",
        icon: "icon-xiaoxiguanli",
        isshow: true,
        component: PageCustomerMsg,
        path: "/admin/setting/customermsg",
      },
    ],
  },
  {
    title: "系统设置",
    icon: "icon-shezhi",
    isshow: true,
    child: [
      {
        title: "开放区域管理",
        icon: "icon-shezhi",
        isshow: true,
        component: PagePublicCity,
        path: "/admin/setting/publiccity",
      },
    ],
  }
];
